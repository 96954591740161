<div class="sidenav-container">
  <nav [class.open]="tabletMenuOpen">
    <app-user-info
      *ngIf="screenWidth < TABLET_BREAK"
      name="Sandro barros"
      company="Nome da Empresa"
      avatarPath="https://picsum.photos/50/50"
      [sidenavMode]="true"
      [logged]="jwtService.hasJwt()"
    ></app-user-info>
    <ul>
      <ng-container *ngFor="let item of menu">
        <li
          *ngIf="!item.isOwner || (item.isOwner && jwtService.isOwner())"
          [class.active]="router.url === item.route[0]"
          (click)="goTo(item)"
        >
          <span class="material-icons">{{ item.icon }}</span>
          <span class="menu-label">{{ item.label }}</span>
        </li>
      </ng-container>
      <li (click)="authService.logoutConfirm()">
        <span class="material-icons">logout</span>
        <span class="menu-label">Desconectar</span>
      </li>
    </ul>
    <div class="copyright">
      <span class="material-icons">copyright</span> 2022 | Política de
      privacidade
    </div>
  </nav>
  <div
    class="backdrop"
    [class.open]="tabletMenuOpen"
    (click)="tabletMenuOpen = !tabletMenuOpen"
  ></div>
  <div class="main-container">
    <main>
      <app-breadcrumb></app-breadcrumb>
      <ng-content></ng-content>
    </main>
  </div>
</div>
