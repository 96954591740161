import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JwtService } from 'src/app/core/services/jwt.service';
import { IGetLoggedAccountResponse } from '../../models/account';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  @Input() mobile: boolean = false;
  @Input() sidenavMode: boolean = false;
  @Input() logged: boolean = false;
  @Input() clean: boolean = false;
  @Input() white: boolean = false;
  @Output() login = new EventEmitter();
  @Output() infoAction = new EventEmitter();
  @Input() customerName!: string;

  constructor(
    public authService: AuthService,
    public jwtService: JwtService
  ) {}

  handleLogin() {
    this.login.emit();
  }
}
