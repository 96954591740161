import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() route: string = '';
  @Input() iconLeft: string = '';
  @Input() label: string = '';
  @Input() sublabel: string = '';
  @Input() iconRight: string = '';
  @Input() hasDelete: boolean = false;
  @Input() hasActionRightIcon: boolean = false;
  @Output() action = new EventEmitter();
  @Output() actionRightIcon = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Input() type: number = 1;

  handleRightIcon() {
    if (this.hasActionRightIcon) {
      this.actionRightIcon.emit();
    } else {
      this.action.emit();
    }
  }
}
