import { Injectable } from '@angular/core';
import { ButtonColor } from 'src/app/shared/components/button/button.component';

interface AlertMessage {
  title: string;
  message: string | string[];
  type: 'success' | 'warning' | 'error' | 'info';
  disableCloseShadow?: boolean;
  buttons?: Array<{
    label: string;
    color: ButtonColor;
    action: () => void;
  }>;
  defaultButton?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alert: AlertMessage | false = false;

  open(alert: AlertMessage) {
    this.alert = alert;
  }

  close() {
    this.alert = false;
  }
}
