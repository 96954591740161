import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { TableComponent } from './components/table/table.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SelectComponent } from './components/select/select.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { RadiogroupComponent } from './components/radiogroup/radiogroup.component';
import { DividerComponent } from './components/divider/divider.component';
import { CurrencyBRLPipe } from './pipes/currency-brl.pipe';
import { NgxMaskModule, IConfig, MaskPipe } from 'ngx-mask';
import { InputCodeComponent } from './components/input-code/input-code.component';
import { SliderComponent } from './components/slider/slider.component';
import { LinkComponent } from './components/link/link.component';
import { PdfTemplateComponent } from './components/pdf-template/pdf-template.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ListItemComponent } from './components/list-item/list-item.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { ModalComponent } from './components/modal/modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CardComponent } from './components/card/card.component';
import { RightSideComponent } from './components/right-side/right-side.component';
import { HeaderComponent } from './components/header/header.component';
import { DisplayInitialsPipe } from './pipes/display-initials.pipe';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { HideDocumentPipe } from './pipes/hide-document.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BalanceCardComponent } from './components/balance-card/balance-card.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { TransactionDayComponent } from './components/transaction-day/transaction-day.component';
import { TransactionItemComponent } from './components/transaction-item/transaction-item.component';
import { CustomComponentComponent } from './components/custom-component/custom-component.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  declarations: [
    InputComponent,
    UserInfoComponent,
    ButtonComponent,
    TableComponent,
    CheckboxComponent,
    SelectComponent,
    PaginatorComponent,
    PageHeaderComponent,
    RadiogroupComponent,
    DividerComponent,
    CurrencyBRLPipe,
    InputCodeComponent,
    SliderComponent,
    LinkComponent,
    PdfTemplateComponent,
    LoadingComponent,
    ListItemComponent,
    InputPasswordComponent,
    ModalComponent,
    CardComponent,
    RightSideComponent,
    HeaderComponent,
    DisplayInitialsPipe,
    ClientFormComponent,
    SideModalComponent,
    HideDocumentPipe,
    MinuteSecondsPipe,
    BalanceCardComponent,
    TransactionDayComponent,
    TransactionItemComponent,
    CustomComponentComponent,
    MenuComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    QRCodeModule,
    ClipboardModule,
    PdfViewerModule,
    InputMaskModule,
  ],
  exports: [
    CommonModule,
    InputComponent,
    UserInfoComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    TableComponent,
    CheckboxComponent,
    SelectComponent,
    PaginatorComponent,
    PageHeaderComponent,
    RadiogroupComponent,
    DividerComponent,
    CurrencyBRLPipe,
    NgxMaskModule,
    InputCodeComponent,
    SliderComponent,
    LinkComponent,
    PdfTemplateComponent,
    LoadingComponent,
    NgxCurrencyModule,
    ListItemComponent,
    InputPasswordComponent,
    ModalComponent,
    QRCodeModule,
    CardComponent,
    RightSideComponent,
    HeaderComponent,
    DisplayInitialsPipe,
    ClientFormComponent,
    SideModalComponent,
    ClipboardModule,
    HideDocumentPipe,
    MinuteSecondsPipe,
    PdfViewerModule,
    BalanceCardComponent,
    InputMaskModule,
    TransactionDayComponent,
    TransactionItemComponent,
    CustomComponentComponent,
    MenuComponent,
  ],
  providers: [
    DatePipe,
    CurrencyBRLPipe,
    DisplayInitialsPipe,
    HideDocumentPipe,
    MinuteSecondsPipe,
    MaskPipe,
    TitleCasePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
