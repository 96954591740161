<section *ngIf="confirmService.confirm">
  <div class="confirm-container">
    <div class="title">
      <h1>{{ confirmService.confirm.title }}</h1>
    </div>
    <div class="message">
      <p>{{ confirmService.confirm.message }}</p>
    </div>
    <div class="actions">
      <app-button
        label="Não"
        color="secondary"
        (click)="confirmService.confirm.click(false); confirmService.close()"
      ></app-button>
      <app-button
        label="Sim"
        color="primary"
        (click)="confirmService.confirm.click(true); confirmService.close()"
      ></app-button>
    </div>
  </div>
</section>
