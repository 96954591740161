import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @ViewChild('input') input!: ElementRef;
  @Input() control!: FormControl;
  @Input() min: number = 0;
  @Input() max: number = 100;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.control.valueChanges.subscribe({
        next: () => {
          this.setSliderBackground(this.control.value);
        },
      });
      this.setSliderBackground(this.control.value);
    }, 0);
  }

  setSliderBackground(value: number) {
    const percent = (value * 100) / this.max;
    this.input.nativeElement.style.background =
      'linear-gradient(to right, #cc0000 0%, #cc0000 ' +
      percent +
      '%, #d3d3d3 ' +
      percent +
      '%, #d3d3d3 100%)';
  }
}
