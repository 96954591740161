import { Component, OnInit } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';

const BreadcrumbRouteLabel = [
  ['pix', 'Pix'],
  ['transfer-by-key', 'Transferência por chave'],
  ['balance', 'Extrato'],
  ['add-client', 'Novo Contato'],
  ['edit-client', 'Editar Contato'],
  ['set-limits', 'Alterar Limites Pix']
];

interface Step {
  label: string;
  link: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  steps: Array<Step> = [];
  constructor(public router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe({
      next: (event) => {
        if (event.constructor === ResolveEnd) {
          const steps = (event as ResolveEnd).url.split('/');
          steps.shift();
          this.steps = steps
            .filter((s) => BreadcrumbRouteLabel.some((l) => l[0] === s))
            .map((s, index: number) => {
              const link: string[] = [];
              for (let i = 0; i < index; i++) {
                link.push(steps[i]);
              }
              link.push(s);
              return {
                label: this.getLabel(s),
                link: `/${link.join('/')}`,
              };
            });
        }
      },
    });
  }

  getLabel(route: string): string {
    const label = BreadcrumbRouteLabel.find((l) => l[0] === route);
    return label ? label[1] : route;
  }

  goTo(step: Step) {
    this.router.navigate([step.link]);
  }
}
