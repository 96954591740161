<section #inputs>
  <input
    *ngFor="let code of codes.controls; let i = index"
    type="text"
    mask="9"
    pattern="\d*"
    [formControl]="getControl(i)"
    (paste)="onPaste($event)"
    [type]="password ? 'password' : ''"
    (keyup)="callNextPrevious($event)"
  />
</section>
