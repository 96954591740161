<section>
  <div
    class="back-foward-button"
    [class.disabled]="page.value === 0"
    [class.hover]="page.value > 0"
    (click)="back()"
  >
    <span class="material-icons">arrow_back</span>
    Anterior
  </div>
  <ng-container *ngFor="let p of pages">
    <div
      *ngIf="p !== -1; else moreContent"
      class="page"
      [class.active]="p === page.value"
      (click)="setPage(p)"
    >
      {{ p + 1 }}
    </div>
    <ng-template #moreContent>
      <div class="page-more">
        <span class="material-icons">more_horiz</span>
      </div>
    </ng-template>
  </ng-container>
  <div
    class="back-foward-button"
    [class.disabled]="this.page.value === this.numberOfPages.value - 1"
    [class.hover]="this.page.value < this.numberOfPages.value - 1"
    (click)="forward()"
  >
    Próximo
    <span class="material-icons">arrow_forward</span>
  </div>
</section>
