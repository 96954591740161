import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RadioItem } from 'src/app/shared/components/radiogroup/radiogroup.component';
import { SelectOption } from 'src/app/shared/components/select/select.component';
import { PixKeyOptionEnum } from 'src/app/shared/enums/pix-key-option.enum';
import { AccountTypeEnum } from '../../enums/account-type.enum';
import { FavoredTypeEnum } from '../../enums/favored-type.enum';

type ClientFormMode = 'add' | 'edit';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent {
  PixKeyOptionEnum = PixKeyOptionEnum;
  @Input() mode: ClientFormMode = 'add';
  @Input() bankList: SelectOption[] = [];
  form = new FormBuilder().group({
    favoredName: ['', [Validators.required, Validators.minLength(3)]],
    favoredTaxNumber: ['', [Validators.required]],
    favoredBank: ['', [Validators.required]],
    favoredAccountNumber: ['', [Validators.required]],
    favoredDigit: ['', [Validators.required]],
    favoredBranch: ['', [Validators.required]],
    favoredPixKeyType: [
      PixKeyOptionEnum.document.toString(),
      [Validators.required],
    ],
    favoredPixKey: ['', [Validators.required]],
    favoredType: [FavoredTypeEnum.Pix, [Validators.required]],
    favoredAccountType: ['0', [Validators.required]],
  });
  FavoredTypeEnum = FavoredTypeEnum;

  get favoredName(): FormControl {
    return this.form.get('favoredName') as FormControl;
  }
  get favoredTaxNumber(): FormControl {
    return this.form.get('favoredTaxNumber') as FormControl;
  }
  get favoredBank(): FormControl {
    return this.form.get('favoredBank') as FormControl;
  }
  get favoredAccountNumber(): FormControl {
    return this.form.get('favoredAccountNumber') as FormControl;
  }
  get favoredDigit(): FormControl {
    return this.form.get('favoredDigit') as FormControl;
  }
  get favoredBranch(): FormControl {
    return this.form.get('favoredBranch') as FormControl;
  }
  get favoredPixKeyType(): FormControl {
    return this.form.get('favoredPixKeyType') as FormControl;
  }
  get favoredPixKey(): FormControl {
    return this.form.get('favoredPixKey') as FormControl;
  }
  get favoredType(): FormControl {
    return this.form.get('favoredType') as FormControl;
  }
  get favoredAccountType(): FormControl {
    return this.form.get('favoredAccountType') as FormControl;
  }

  accountTypes: RadioItem[] = [
    { label: 'Conta corrente', value: AccountTypeEnum['Conta corrente'] },
    { label: 'Conta poupança', value: AccountTypeEnum['Conta poupança'] },
  ];

  updateAllFields() {
    this.favoredName.updateValueAndValidity();
    this.favoredTaxNumber.updateValueAndValidity();
    this.favoredBank.updateValueAndValidity();
    this.favoredAccountNumber.updateValueAndValidity();
    this.favoredDigit.updateValueAndValidity();
    this.favoredBranch.updateValueAndValidity();
    this.favoredPixKey.updateValueAndValidity();
    // this.favoredType.updateValueAndValidity();
  }

  constructor() {
    this.favoredType.valueChanges.subscribe({
      next: (value) => {
        this.handlePixAccount(value);
      },
    });
    this.handlePixAccount(this.favoredType.value);
    // this.favoredDigit.valueChanges.subscribe({
    //   next: () => {
    //     if (!'0123456789Xx'.split('').includes(this.favoredDigit.value)) {
    //       this.favoredDigit.setValue('');
    //     }
    //   },
    // });
    this.favoredPixKeyType.valueChanges.subscribe({
      next: () => {
        this.favoredPixKey.setValue('');
      },
    });
  }

  handlePixAccount(value: FavoredTypeEnum) {
    if (value === FavoredTypeEnum.Pix) {
      this.favoredBank.removeValidators([Validators.required]);
      this.favoredAccountNumber.removeValidators([Validators.required]);
      this.favoredDigit.removeValidators([Validators.required]);
      this.favoredBranch.removeValidators([Validators.required]);
      this.favoredType.removeValidators([Validators.required]);
      this.favoredAccountType.removeValidators([Validators.required]);
      this.favoredPixKey.addValidators([Validators.required]);
    } else {
      this.favoredBank.addValidators([Validators.required]);
      this.favoredAccountNumber.addValidators([Validators.required]);
      this.favoredDigit.addValidators([Validators.required]);
      this.favoredBranch.addValidators([Validators.required]);
      this.favoredType.addValidators([Validators.required]);
      this.favoredAccountType.addValidators([Validators.required]);
      this.favoredPixKey.removeValidators([Validators.required]);
    }
    this.favoredBank.updateValueAndValidity();
    this.favoredAccountNumber.updateValueAndValidity();
    this.favoredDigit.updateValueAndValidity();
    this.favoredBranch.updateValueAndValidity();
    // this.favoredType.updateValueAndValidity();
    this.favoredPixKey.updateValueAndValidity();
  }

  getMask() {
    switch (this.favoredPixKeyType.value) {
      case PixKeyOptionEnum.document.toString():
        return '000.000.000-00||00.000.000/0000-00';
      case PixKeyOptionEnum.phone.toString():
        return '(00) 00000-0000';
      case PixKeyOptionEnum.email.toString():
      case PixKeyOptionEnum.random.toString():
      default:
        return '';
    }
  }
}
