import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGetTransactionListResponse, IGetTransactionListResponseItem } from 'src/app/shared/models/transaction';

@Component({
  selector: 'app-transaction-day',
  templateUrl: './transaction-day.component.html',
  styleUrls: ['./transaction-day.component.scss']
})
export class TransactionDayComponent implements OnInit {
  @Output() itemAction = new EventEmitter<IGetTransactionListResponseItem>();
  @Input() transaction!: IGetTransactionListResponse;
  @Input() hasActions: boolean = true;
  @Input() showBalance: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
