<section>
  <div class="table">
    <div class="header" [ngStyle]="getHeaderRowStyle()">
      <ng-container *ngFor="let headerCol of columns" [ngSwitch]="headerCol.type">
        <ng-container *ngSwitchCase="'icon'">
          <div class="header-col icon-container">
            {{ headerCol.label }}
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="header-col">
            {{ headerCol.label }}
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="body" #tableBody>
      <div class="row" *ngFor="let row of rows" [ngStyle]="getRowStyle()">
        <div
          class="row-col"
          [class.has-action]="col.click"
          *ngFor="let col of columns"
          (click)="handleColumnClick(row, col, $event)"
        >
          <ng-container *ngIf="col.custom; else notCustom">
            {{ col.custom(row[col.field], row) }}
          </ng-container>
          <ng-template #notCustom>
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'icon'">
                <div class="icon-container">
                  <span class="material-icons">{{ row[col.field] }}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'icon-edit'">
                <span class="material-icons">edit</span>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <span>{{ row[col.field] | date: "dd/MM/YYYY" }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'datetime'">
                <span>{{ row[col.field] | date: "dd/MM/YYYY HH:mm" }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                <span>{{ row[col.field] | currencyBRL }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'phone'">
                <span>{{ row[col.field] | mask: "(00) 00000-0000" }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'cpf'">
                <span>{{ row[col.field] | mask: "000.000.000-00" }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'link'">
                <app-link [label]="row[col.field]"></app-link>
              </ng-container>
              <ng-container *ngSwitchCase="'component'">
                <app-custom-component [component]="col.component" [data]="row"></app-custom-component>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span>{{ row[col.field] }}</span>
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="no-data-loading" [ngStyle]="{ gridColumn: '' }" *ngIf="loading">
    <app-loading [show]="true"></app-loading>
  </div>
  <div class="no-data-loading" [ngStyle]="getRowStyle()" *ngIf="!loading && rows.length === 0 && !hasSearch">
    Nenhum item encontrado
  </div>
  <div class="no-data-loading" [ngStyle]="getRowStyle()" *ngIf="!loading && rows.length === 0 && hasSearch">
    Nenhum item encontrado com a pesquisa
  </div>
</section>
