<header [class.mobile]="screenWidth < TABLET_BREAK">
  <span
    *ngIf="screenWidth < TABLET_BREAK && showMobileMenu"
    class="material-icons tablet-menu-icon"
    (click)="tabletMenuClick.emit()"
    >menu</span
  >
  <div class="header-logo" (click)="goHome()">
    <img *ngIf="screenWidth < TABLET_BREAK; else logoMobile" src="assets/images/logo.png" alt="" />
    <ng-template #logoMobile>
      <img src="assets/images/logo.png" alt="" />
    </ng-template>
  </div>
  <app-user-info
    *ngIf="showLogin"
    [mobile]="screenWidth < TABLET_BREAK"
    [customerName]="customerName"
    [logged]="!!authService.isLogged()"
    (login)="handleLogin()"
    (infoAction)="router.navigate(['/account/select'])"
  ></app-user-info>
</header>
