import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ButtonColor = "primary" | "secondary" | "tertiary" | "white";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Output() action = new EventEmitter();
  @Input() label: string = "";
  @Input() color: ButtonColor = "primary";
  @Input() icon?: string;
  @Input() disabled: boolean = false;

  handleAction() {
    if (!this.disabled && this.action) {
      this.action.emit();
    }
  }

}
