import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideDocument'
})
export class HideDocumentPipe implements PipeTransform {

  transform(value: string | number): string {
    return value.toString();
  }

}
