import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-right-side',
  templateUrl: './right-side.component.html',
  styleUrls: ['./right-side.component.scss'],
})
export class RightSideComponent {
  @Input() open: boolean = false;
  @Input() hideClose: boolean = false;
  @Output() close = new EventEmitter();
}
