import { Injectable } from '@angular/core';
import { ButtonColor } from 'src/app/shared/components/button/button.component';

interface ConfirmMessage {
  title: string;
  message: string | string[];
  click: (answer: boolean) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  confirm: ConfirmMessage | false = false;

  open(confirm: ConfirmMessage) {
    this.confirm = confirm;
  }

  close() {
    this.confirm = false;
  }
}
