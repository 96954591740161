import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PixinTypeEnum } from 'src/app/shared/enums/pixin-type.enum';
import { PixoutTypeEnum } from 'src/app/shared/enums/pixout-type.enum';
import { IGetTransactionListResponseItem } from 'src/app/shared/models/transaction';
import { CurrencyBRLPipe } from 'src/app/shared/pipes/currency-brl.pipe';

@Component({
  selector: 'app-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss']
})
export class TransactionItemComponent implements OnInit {
  @Output() action = new EventEmitter<IGetTransactionListResponseItem>()
  @Input() transactionItem!: IGetTransactionListResponseItem;
  @Input() hasActions: boolean = true;
  PixoutTypeEnum = PixoutTypeEnum;
  PixinTypeEnum = PixinTypeEnum;

  constructor(public currencyBRLPipe: CurrencyBRLPipe) { }

  ngOnInit(): void {
  }

  getTitle(item: IGetTransactionListResponseItem) {
    try {
      const title = item.description.split('(')[0].trim();
      return title;
    } catch (error) {
      return item.description;
    }
  }

  getSubtitle(item: IGetTransactionListResponseItem) {
    try {
      const title = item.description.split(')')[0].split('(')[1].trim();
      return title;
    } catch (error) {
      return item.description;
    }
  }

  handleAction(item: IGetTransactionListResponseItem) {
    if (this.hasActions && item.hasVoucher) {
      this.action.emit(item);
    }
  }
}
