<div
  class="user-info-container"
  [class.user-info-all-info]="!mobile"
  [class.sidenav-mode]="sidenavMode"
  [class.login-button]="!logged"
  [class.clean]="clean"
>
  <ng-container *ngIf="logged; else notLogged">
    <div class="info" [class.mobile]="mobile" (click)="infoAction.emit()">
      <span class="name" [title]="jwtService.getName()">{{
        jwtService.getName()
      }}</span>
      <label
        class="company"
        [title]="jwtService.getCustomerName()"
        *ngIf="customerName"
      >
        {{ customerName }}
        <span class="material-icons">chevron_right</span>
      </label>
    </div>
    <div *ngIf="!mobile" class="avatar">
      {{ jwtService.getName() | displayInitials }}
    </div>
    <div
      *ngIf="!mobile && !clean"
      class="logout"
      (click)="authService.logoutConfirm()"
    >
      <span class="material-icons">logout</span>
      <span>Sair</span>
    </div>
  </ng-container>
  <ng-template #notLogged>
    <app-button label="LOGIN" (action)="handleLogin()"></app-button>
  </ng-template>
</div>
