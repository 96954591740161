// 1422 x 668.15
import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SidenavComponent } from './core/components/sidenav/sidenav.component';
import { JwtService } from './core/services/jwt.service';
import { AccountService } from './shared/services/account.service';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(SidenavComponent) sidenavComponent!: SidenavComponent;
  customerName!: string;

  constructor(
    private router: Router,
    public jwtService: JwtService,
    private userService: UserService,
    private authService: AuthService,
    private accountService: AccountService
  ) {
    this.router.events.subscribe({
      next: (event) => {
        if (event.constructor === NavigationEnd && this.authService.isLogged()) {
          if ((event as NavigationEnd).url !== '/account/select') {
            this.customerName = '';
            this.accountService.getLoggedAccount().subscribe({
              next: (response) => {
                this.customerName = response.data.customerName;
              },
            });
          }
        }
      },
    });
  }

  toggleTabletMenu() {
    this.sidenavComponent.tabletMenuOpen =
      !this.sidenavComponent.tabletMenuOpen;
  }

  showSidenav(): boolean {
    const routesToHideSidenav = [
      '/transaction-receipt',
      '/balance/statement-list-pdf',
      '/recover-password',
      '/reset-password',
      '/change-password',
      '/term',
      '/account/select'
    ];
    let _show = true;
    routesToHideSidenav.forEach((route) => {
      if (this.router.url.includes(route) || this.router.url === '/') {
        _show = false;
      }
    });
    return _show;
  }

  showHeader(): boolean {
    const routesToHideHeader = [
      '/balance/statement-list-pdf',
      '/recover-password',
      '/reset-password',
      '/change-password',
      '/term',
    ];
    let _show = true;
    routesToHideHeader.forEach((route) => {
      if (this.router.url.includes(route) || this.router.url === '/') {
        _show = false;
      }
    });
    return _show;
  }

  closeSidenavMenu() {
    this.sidenavComponent.tabletMenuOpen = false;
  }
}
