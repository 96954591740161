import { Component } from '@angular/core';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  Array = Array;
  constructor(public alertService: AlertService) {}

  getIcon() {
    if (this.alertService.alert) {
      switch (this.alertService.alert.type) {
        case 'success':
          return 'check_circle';
        case 'error':
          return 'cancel';
        case 'warning':
          return 'warning';
        case 'info':
          return 'info';
      }
    }
    return '';
  }

  close(event: MouseEvent) {
    if (this.alertService.alert && this.alertService.alert.disableCloseShadow)
      return;
    const { clientX, clientY } = event;
    const elements = document.elementsFromPoint(clientX, clientY);
    if (!elements.some((el) => el.classList.contains('alert-container'))) {
      if (this.alertService.alert && this.alertService.alert.defaultButton) {
        this.alertService.alert.defaultButton();
      }
      this.alertService.close();
    }
  }

  handleOK() {
    if (this.alertService.alert && this.alertService.alert.defaultButton) {
      this.alertService.alert.defaultButton();
    }
    this.alertService.close();
  }
}
