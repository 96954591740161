import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayInitials',
})
export class DisplayInitialsPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) return '';
    if (value.toString().split(' ').length > 1) {
      return `${value.toString().split(' ')[0][0]}${
        value.toString().split(' ')[1][0]
      }`.toUpperCase();
    }
    return value.toString().substring(0, 2).toUpperCase();
  }
}
