import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface RadioItem {
  label: string;
  value: string | number;
}

@Component({
  selector: 'app-radiogroup',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.scss'],
})
export class RadiogroupComponent {
  @Input() control!: FormControl;
  @Input() items: RadioItem[] = [];
  @Input() name: string = '';
  @Input() inline: boolean = false;
  @Input() disabled: boolean = false;

  handleChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.control.setValue(target.value);
  }
}
