import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyBRL',
})
export class CurrencyBRLPipe implements PipeTransform {
  transform(value: number | string): string {
    let _value = 'R$ -';
    if (!isNaN(Number(value))) {
      _value = Number(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return _value;
  }
}
