<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="1" class="type-1">
    <span class="material-icons" (click)="action.emit()">{{ iconLeft }}</span>
    <div (click)="action.emit()">
      <p>{{ label }}</p>
      <p *ngIf="sublabel">{{ sublabel }}</p>
    </div>
    <span *ngIf="hasDelete" (click)="delete.emit()" class="delete material-icons"
      >delete</span
    >
    <span (click)="handleRightIcon()" class="material-icons">{{
      iconRight
    }}</span>
  </div>
  <div *ngSwitchCase="2" class="type-2">
    <label>{{ label }}</label>
    <div>
      <p>{{ sublabel }}</p>
      <span (click)="handleRightIcon()" class="right-icon material-icons">{{
        iconRight
      }}</span>
    </div>
  </div>
</ng-container>
