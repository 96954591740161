import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError, map, of, tap } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from './alert.service';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private jwtService: JwtService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwt = this.jwtService.getJwt();
    if (jwt) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`,
        },
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>): any => {
        if (event.type === 4) {
          if (event.body.status === 'Error') {
            this.alertService.open({
              title: 'Atenção',
              message: event.body.errorMessage,
              type: 'error',
            });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.alertService.open({
            title: 'Atenção',
            type: 'error',
            message: "Sessão expirada, faça login novamente",
          });
          this.authService.logout();
        } else if (error.error && error.error.ErrorMessage) {
          this.alertService.open({
            title: 'Atenção',
            type: 'error',
            message: error.error.ErrorMessage,
          });
        } else if (error.error && error.error.ValidationErrors) {
          let messages = [];
          for (const key in error.error.ValidationErrors) {
            messages.push(...error.error.ValidationErrors[key]);
          }
          this.alertService.open({
            title: 'Atenção',
            type: 'error',
            message: messages,
          });
        } else {
          this.alertService.open({
            title: 'Atenção',
            type: 'error',
            message:
              'Ocorreu um erro no servidor, aguarde ou contate um administrador!',
          });
        }
        return throwError(error);
      })
    );
  }
}
