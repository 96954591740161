<section [class.inline]="inline">
  <label *ngFor="let item of items">
    <!-- TODO: Esse span ta gerando o erro "ExpressionChangedAfterItHasBeenCheckedError" por conta do ngIf -->
    <span
      class="material-icons"
      *ngIf="item.value.toString() === this.control.value; else unchecked"
      >radio_button_checked</span
    >
    <ng-template #unchecked>
      <span class="material-icons">radio_button_unchecked</span>
    </ng-template>
    <input
      type="radio"
      [name]="name"
      (change)="handleChange($event)"
      [value]="item.value"
    />
    <span>{{ item.label }}</span>
  </label>
</section>
