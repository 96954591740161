import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
})
export class InputCodeComponent implements AfterViewInit {
  @Input() control!: FormControl;
  @Input() numberOfChar: number = 4;
  @Input() password: boolean = false;
  @ViewChild('inputs') inputs!: ElementRef;
  codes = new FormArray([]);

  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      for (let i = 0; i < this.numberOfChar; i++) {
        this.codes.push(new FormControl(''));
      }
      this.control.value.split('').forEach((value: string, index: number) => {
        this.codes.at(index).setValue(value);
      });
      this.codes.valueChanges.subscribe(() => {
        this.control.setValue(this.codes.value.join(''));
      });
    }, 0);
  }

  getControl(index: number): FormControl {
    return this.codes.at(index) as FormControl;
  }

  callNextPrevious(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      const previous: HTMLInputElement = (event.target as HTMLInputElement)
        .previousElementSibling as HTMLInputElement;
      if (previous) {
        setTimeout(() => {
          previous.focus();
        }, 30);
      }
    } else {
      const next: HTMLInputElement = (event.target as HTMLInputElement)
        .nextElementSibling as HTMLInputElement;
      if (next) {
        setTimeout(() => {
          next.focus();
        }, 30);
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    const paste: string | undefined = event.clipboardData?.getData('text');
    if (paste) {
      const _codes = paste.split('');
      if (_codes.length === this.numberOfChar) {
        for (let i = 0; i < this.numberOfChar; i++) {
          this.codes.at(i).setValue(_codes[i]);
        }
      }
    }
  }
}
