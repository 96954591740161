import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { labelJwt } from 'src/app/shared/constants/jwt';
import { Jwt } from 'src/app/shared/models/jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  protected decode(): Jwt | null {
    const jwt = sessionStorage.getItem(labelJwt);
    if (jwt) {
      return jwt_decode(jwt) as Jwt;
    }
    return null;
  }

  hasJwt(): boolean {
    return !!this.decode();
  }

  getJwt(): string {
    return sessionStorage.getItem(labelJwt) || '';
  }

  getName(): string {
    return this.decode()?.name || '';
  }

  getCustomerName(): string {
    return this.decode()?.customerName || '';
  }

  getUserId(): string {
    return this.decode()?.sub || '';
  }

  getAccountId(): string {
    return this.decode()?.accountId || '';
  }

  isOwner(): boolean {
    return this.decode()?.profile === 'owner';
  }
}
