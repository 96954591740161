<section [class.disabled]="disabled">
  <span *ngIf="label" class="label" [class.secondary]="mode === 'secondary'">{{
    label
  }}</span>
  <label
    class="input-container"
    [class.reverse]="iconLeft"
    [class.has-error]="errors.length && showErrors"
    [class.secondary]="mode === 'secondary'"
  >
    <ng-container [ngSwitch]="type">
      <input
        *ngSwitchCase="'currency'"
        type="text"
        placeholder="{{ placeholder }}"
        [formControl]="control"
        currencyMask
        (paste)="paste"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
      />
      <div *ngSwitchCase="'date'" class="date-field">
        <div class="date-container" (click)="openDate($event)">
          <span>{{ control.value | date: "dd/MM/YYYY" }}</span>
          <span *ngIf="!disabled" class="material-icons icon"
            >calendar_today</span
          >
        </div>
        <input
          placeholder="{{ placeholder }}"
          type="date"
          [formControl]="control"
        />
      </div>
      <input
        *ngSwitchCase="'password'"
        placeholder="{{ placeholder }}"
        [type]="showPassword ? 'text' : 'password'"
        [formControl]="control"
      />
      <ng-container *ngSwitchDefault>
        <input
          *ngIf="mask"
          [type]="type"
          placeholder="{{ placeholder }}"
          [formControl]="control"
          [mask]="mask"
        />
        <input
          *ngIf="inputMask"
          [type]="type"
          placeholder="{{ placeholder }}"
          [formControl]="control"
          [inputMask]="inputMask"
        />
        <input
          *ngIf="!inputMask && !mask"
          [type]="type"
          placeholder="{{ placeholder }}"
          [formControl]="control"
          (paste)="paste.emit($event)"
        />
      </ng-container>
    </ng-container>
    <span
      *ngIf="type === 'password'"
      (click)="showPassword = !showPassword"
      class="material-icons input-icon password-view"
    >
      {{ showPassword ? "visibility_off" : "visibility" }}
    </span>
    <span
      *ngIf="icon && type !== 'password'"
      class="material-icons input-icon"
      >{{ icon }}</span
    >
  </label>
  <div class="error-container" *ngIf="showErrors">
    <span *ngFor="let error of errors">{{ error }}</span>
  </div>
</section>
