import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss']
})
export class SideModalComponent {
  @Output() backdropAction = new EventEmitter();
  @Input() show: boolean = false;
}
